const FooterData = [
  {
    title: "INFORMATION",
    submenu: [
        {
            title: "Home",
            link: "/"
        },
      {
        title: "About",
        link: "/about-us",
      },
      {
        title: "Services",
        link: "/service-sales",
      },
      
    ],
  },
  // {
  //     title: "customer",
  //     submenu: [
  //         {
  //             title: "Client support",
  //             link: "/page/faq-s"
  //         },
  //         {
  //             title: "Pricing packages",
  //             link: "/page/pricing-packages"
  //         },
  //         {
  //             title: "Company history",
  //             link: "/page/our-story"
  //         },
  //         {
  //             title: "Our process",
  //             link: "/page/our-process"
  //         },
  //     ]
  // },
  {
    title: "CUSTOMER SERVICE",
    submenu: [
      {
        title: "Career",
        link: "/career"
      },
      {
        title: "Clients",
        link: "/clients"
      },
      // {
      //   title: "Terms & Condition",
      //   link: "/",
      // },
      // {
      //   title: "Privacy Policy",
      //   link: "/",
      // },
      {
        title: "Contact Us",
        link: "/contact",
      },
    ],
  },
  // {
  //     title: "Services",
  //     submenu: [
  //         {

  //             title: "Brand experience",
  //             link: "/page/our-services"
  //         },
  //         {
  //             title: "e-Commerce website",
  //             link: "/page/our-services"
  //         },
  //         {
  //             title: "Content writing",
  //             link: "/page/our-services"
  //         },
  //         {
  //             title: "Marketing strategy",
  //             link: "/page/our-services"
  //         },
  //     ]
  // },
  // {
  //     title: "Resources",
  //     submenu: [
  //         {
  //             title: "Theme guide",
  //             link: "/page/faq-s"
  //         },
  //         {
  //             title: "Support desk",
  //             link: "/page/faq-s"
  //         },
  //         {
  //             title: "What we offer",
  //             link: "/page/what-we-offer"
  //         },
  //         {
  //             title: "Company history",
  //             link: "/page/our-story"
  //         },
  //     ]
  // },
  // {
  //     title: "Categories",
  //     submenu: [
  //         {
  //             title: "For men",
  //             link: "/shop/shop-wide"
  //         },
  //         {
  //             title: "For woman",
  //             link: "/shop/shop-wide"
  //         },
  //         {
  //             title: "Accessories",
  //             link: "/shop/shop-wide"
  //         },
  //         {
  //             title: "Collections",
  //             link: "/shop/shop-wide"
  //         },
  //     ]
  // },
  // {
  //     title: "Adavantages",
  //     submenu: [
  //         {
  //             title: "Free delivery",
  //             link: "/page/faq-s"
  //         },
  //         {
  //             title: "100 days refund",
  //             link: "/page/faq-s"
  //         },
  //         {
  //             title: "Multiple payments",
  //             link: "/page/faq-s"
  //         },
  //         {
  //             title: "Sustainable",
  //             link: "/page/faq-s"
  //         },
  //     ]
  // },
];

export default FooterData