import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { m } from "framer-motion";
import { useForm } from "react-hook-form";

//components
import { fadeIn } from "../../Functions/GlobalAnimations";
import Buttons from "../../Components/Button/Buttons";

const CareerContact = () => {
  const [formLoading, setFormLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "all" });

  const onSubmit = (data) => {
    console.log(data);
    setFormLoading(false);
  };

  return (
    <>
      <m.section
        {...fadeIn}
        id="comments"
        className="pt-0 py-[130px] lg:py-[90px] md:py-[75px] sm:py-[50px] overflow-hidden"
      >
        <Container>
          <Row className="justify-center ">
            <Col lg={9} className="mb-16 sm:mb-8 ">
              <h6 className="font-serif text-darkgray font-medium mb-[5px]">
              Apply Now for Exciting Opportunities.
              </h6>
              <div className="mb-[5px]">
              Fill your details below and we'll be in touch if your skills match an open position.
              <span className="text-[#fb4f58]">*</span>
              </div>
            </Col>
          </Row>
          <Row className="justify-center">
            <Col lg={9}>
              <form onSubmit={handleSubmit(onSubmit)} className="row mb-[30px]">
                <Col md={6} sm={12} xs={12}>
                  <label className="translate-x-[5px]">Name</label>
                  <input
                    label={
                      <span className="inline-block mb-[15px] ">
                        Your name <span className="text-red-500 ">*</span>
                      </span>
                    }
                    type="text"
                    name="name"
                    labelClass="mb-[25px]"
                    className="rounded-[5px] py-[15px] px-[20px] w-full border-[1px] border-solid border-[#dfdfdf] mt-[0px] mb-[20px]"
                    placeholder="Enter your name"
                    style={{ backgroundColor: "transparent" }}
                    {...register("name", {
                      required: {
                        value: true,
                        message: "Name is required",
                      },
                    })}
                  />

                  <div className="absolute text-[tomato] text-[12px] translate-y-[-25px] translate-x-[10px]">
                    {errors.name?.message}
                  </div>
                </Col>
                <Col md={6} sm={12} xs={12}>
                  <label className="translate-x-[5px]">Email</label>
                  <input
                    label={
                      <span className="inline-block mb-[15px]">
                        Your email address
                        <span className="text-red-500">*</span>
                      </span>
                    }
                    type="email"
                    name="email"
                    labelClass="mb-[25px] "
                    className="rounded-[5px] py-[15px] px-[20px] w-full border-[1px] border-solid border-[#dfdfdf] sm:mb-[20px] ]"
                    placeholder="Enter your email"
                    style={{ backgroundColor: "transparent" }}
                    {...register("email", {
                      required: {
                        value: true,
                        message: "Email is required",
                      },
                      pattern: {
                        value:
                          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
                        message: "Enter valid email",
                      },
                    })}
                  />
                  <div className="absolute text-[tomato] text-[12px] translate-y-[-3px] translate-x-[10px] sm:translate-y-[-25px]">
                    {errors.email?.message}
                  </div>
                </Col>

                <Col md={6} sm={12} xs={12}>
                  <label className="translate-x-[5px]">Address</label>
                  <input
                    label={
                      <span className="inline-block mb-[15px]">
                        Address <span className="text-red-500">*</span>
                      </span>
                    }
                    type="text"
                    name="address"
                    labelClass="mb-[25px]"
                    className="rounded-[5px] py-[15px] px-[20px] w-full border-[1px] border-solid border-[#dfdfdf] mb-[20px] sm:mt-[10px] "
                    placeholder="Enter your Address"
                    style={{ backgroundColor: "transparent" }}
                    {...register("address", {
                      required: {
                        value: true,
                        message: "Address is required",
                      },
                    })}
                  />
                  <div className="absolute text-[tomato] text-[12px] translate-y-[-25px] translate-x-[10px]">
                    {errors.address?.message}
                  </div>
                </Col>

                {/*  */}
                <Col md={6} sm={12} xs={12}>
                  <label className="translate-x-[5px]">Contact</label>
                  <input
                    label={
                      <span className="inline-block mb-[15px]">
                        Contact <span className="text-red-500">*</span>
                      </span>
                    }
                    type="tel"
                    name="contact"
                    labelClass="mb-[25px]"
                    className="rounded-[5px] py-[15px] px-[20px] w-full border-[1px] border-solid border-[#dfdfdf] sm:mb-[20px]  "
                    placeholder="Enter your Contact"
                    style={{ backgroundColor: "transparent" }}
                    {...register("contact", {
                      required: {
                        value: true,
                        message: "Phone number is required",
                      },
                      minLength: {
                        value: 10,
                        message: "Enter Valid Phone number",
                      },
                      maxLength: {
                        value: 12,
                        message: "Enter Valid Phone number",
                      },
                    })}
                  />
                  <div className="absolute text-[tomato] text-[12px] translate-y-[-3px] translate-x-[10px] sm:translate-y-[-25px]">
                    {errors.contact?.message}
                  </div>
                </Col>

                {/*  */}
                <Col md={6} sm={12} xs={12}>
                  <label className="translate-x-[5px]">Qualification</label>
                  <input
                    label={
                      <span className="inline-block mb-[15px]">
                        Qualification <span className="text-red-500">*</span>
                      </span>
                    }
                    type="text"
                    name="qualification"
                    labelClass="mb-[25px]"
                    className="rounded-[5px] py-[15px] px-[20px] w-full border-[1px] border-solid border-[#dfdfdf] mb-[20px] "
                    placeholder="Enter your Qualification"
                    style={{ backgroundColor: "transparent" }}
                    {...register("qualification", {
                      required: {
                        value: true,
                        message: "Qualification is required",
                      },
                    })}
                  />
                  <div className="absolute text-[tomato] text-[12px] translate-y-[-25px] translate-x-[10px]">
                    {errors.qualification?.message}
                  </div>
                </Col>
                {/*  */}
                <Col md={6} sm={12} xs={12}>
                  <label className="translate-x-[5px]">Subject</label>
                  <input
                    label={
                      <span className="inline-block mb-[15px]">
                        Subject <span className="text-red-500">*</span>
                      </span>
                    }
                    type="text"
                    name="subject"
                    labelClass="mb-[25px]"
                    className="rounded-[5px] py-[15px] px-[20px] w-full border-[1px] border-solid border-[#dfdfdf] sm:mb-[20px] "
                    placeholder="Enter your Subject"
                    style={{ backgroundColor: "transparent" }}
                    {...register("subject", {
                      required: {
                        value: true,
                        message: "Subject is required",
                      },
                    })}
                  />
                  <div className="absolute text-[tomato] text-[12px] translate-y-[-3px] translate-x-[10px] sm:translate-y-[-25px]">
                    {errors.subject?.message}
                  </div>
                </Col>

                <Col md={12} sm={12} xs={12}>
                  {/* <label className="mb-[15px]">Your comment</label> */}
                  <textarea
                    className="mb-[2.5rem] rounded-[4px] py-[15px] px-[20px] h-[120px] w-full bg-transparent border border-[#dfdfdf] text-md resize-none  mt-[10px] sm:mt-[10px]"
                    rows="6"
                    name="comment"
                    placeholder="Enter your comment"
                    {...register("comment", {
                      required: {
                        value: true,
                        message: "Comment is required",
                      },
                    })}
                  ></textarea>
                  <div className="absolute text-[tomato] text-[12px] translate-y-[-38px] translate-x-[10px] sm:translate-y-[-38px]">
                    {errors.comment?.message}
                  </div>
                </Col>
                <Col>
                  <Buttons
                    type="submit"
                    className={`tracking-[0.5px] btn-fill rounded-[2px] font-medium  ${
                      formLoading ? "loading" : ""
                    }`}
                    themeColor="#232323"
                    size="md"
                    color="#fff"
                    title="Submit Application"
                  />
                </Col>
                {/* <AnimatePresence> */}

                {/* <m.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  <textarea
                    className="mt-[20px] py-[10px]"
                    theme="message-box01"
                    variant="success"
                    message="Your message has been sent successfully!"
                  />
                </m.div> */}

                {/* </AnimatePresence> */}
              </form>
            </Col>
          </Row>
        </Container>
      </m.section>
    </>
  );
};

export default CareerContact;
