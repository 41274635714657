import React from "react";
import { Container } from "react-bootstrap";
import "./Banner.scss";
const Banner = (props) => {
  return (
    <div>
      <section
        className="breadcrumb_section object-cover bg-center"
        style={{ backgroundImage: `url(${props.image})` }}
      >
        <Container>
          <div className="breadcrumb-title">
            <h2 className="heading">{props.title}</h2>
            {/* <ul>
              <Link to="/">Home </Link>
              <div className='flex items-center ml-[5px]'> <span className='text-white'>/</span> <p> {props.title}</p>
              </div>
            </ul> */}
          </div>
        </Container>
      </section>
    </div>
  );
};

export default Banner;
