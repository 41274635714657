const HeaderDataAlpha = [
  {
    title: "Home",
    link: "/",
  },
  {
    title: "About",
    link: "/about-us",
  },

  {
    title: "Calibration Facility",
    link: "/calibration-facility",
  },
  {
    title: "Service And Sales",
    link: "/service-sales",
  },
  {
    title: "Clients",
    link: "/clients",
  },
  {
    title: "Career",
    link: "/career",
  },

  {
    title: "Gallery",
    link: "/gallery",
    dropdown: [
      {
        title: "Miscellaneous Instruments",
        link: "/gallery",
        // dropdown: [
        //   {
        //     title: "Digital PH Meter",
        //     link: "/services",
        //   },
        //   {
        //     title: "Digital TDS Meter",
        //     link: "/services",
        //   },
        //   {
        //     title: "Auto Level",
        //     link: "/services",
        //   },
        //   {
        //     title: "Dumpy Level",
        //     link: "/services",
        //   },
        //   {
        //     title: "Therdolite",
        //     link: "/services",
        //   },
        //   {
        //     title: "Cube Mould",
        //     link: "/services",
        //   },
        //   {
        //     title: "Core Rammer",
        //     link: "/services",
        //   },
        //   {
        //     title: "Core Cutter",
        //     link: "/services",
        //   },
        //   {
        //     title: "CBR Mould",
        //     link: "/services",
        //   },
        //   {
        //     title: "Flexure Mould",
        //     link: "/services",
        //   },
        //   {
        //     title: "Flakiness & Elongation Test",
        //     link: "/services",
        //   },
        //   {
        //     title: "Bulk Density Bucket",
        //     link: "/services",
        //   },
        //   {
        //     title: "Sand Purring Cylinder",
        //     link: "/services",
        //   },
        //   {
        //     title: "Specific Gravity Bottle",
        //     link: "/services",
        //   },
        //   {
        //     title: "Manila Rope",
        //     link: "/services",
        //   },
        //   {
        //     title: "Total Station",
        //     link: "/services",
        //   },
        //   {
        //     title: "AIV Test Apparatus",
        //     link: "/services",
        //   },
        //   {
        //     title: "Metal Cut Off saw",
        //     link: "/services",
        //   },
        //   {
        //     title: "Specific Gravity & Water Absorption Apparatus",
        //     link: "/services",
        //   },
        //   {
        //     title: "Proctor Test Apparatus With Mould",
        //     link: "/services",
        //   },
        //   {
        //     title: "Bitumen Extractor",
        //     link: "/services",
        //   },
        //   {
        //     title: "Cone Penetrometer",
        //     link: "/services",
        //   },
        //   {
        //     title: "Digital Penetrometer",
        //     link: "/services",
        //   },
        //   {
        //     title: "Saybolt Viscometer",
        //     link: "/services",
        //   },
        //   {
        //     title: "CBC Lock Parallel Thickness Gauge",
        //     link: "/services",
        //   },
        //   {
        //     title: "Friction Shoe Gauge",
        //     link: "/services",
        //   },
        //   {
        //     title: "Opt. Handle Gauge",
        //     link: "/services",
        //   },
        //   {
        //     title: "CBC Contour gauge",
        //     link: "/services",
        //   },
        //   {
        //     title: "Bolster column gib gauge",
        //     link: "/services",
        //   },
        //   {
        //     title: "Adaptor column gib gauge",
        //     link: "/services",
        //   },
        //   {
        //     title: "Adaptor side lug Gauge",
        //     link: "/services",
        //   },
        //   {
        //     title: "Adaptor side gauge",
        //     link: "/services",
        //   },
        //   {
        //     title: "Bolster column gauge",
        //     link: "/services",
        //   },
        //   {
        //     title: "Pedestal jaw gauge(Short)",
        //     link: "/services",
        //   },
        //   {
        //     title: "Pedestal jaw gauge(Long)",
        //     link: "/services",
        //   },
        //   {
        //     title: "Bolster land & Rotation lug gauge",
        //     link: "/services",
        //   },
        //   {
        //     title: "Knuckle stretch cum nose wear limit gauge",
        //     link: "/services",
        //   },
        //   {
        //     title: "Anti-rotation lug gauge",
        //     link: "/services",
        //   },
        //   {
        //     title: "Centre pivot worn out gauge",
        //     link: "/services",
        //   },
        //   {
        //     title: "Bolster Combined wear gauge",
        //     link: "/services",
        //   },
        //   {
        //     title: "CBC Height Gauge(Normal Not vernier)",
        //     link: "/services",
        //   },
        //   {
        //     title: "Lock Contour knuckle arm side",
        //     link: "/services",
        //   },
        //   {
        //     title: "Lock Contour Guard arm side gauge",
        //     link: "/services",
        //   },
        //   {
        //     title: "Tread Wear Gauge",
        //     link: "/services",
        //   },
        //   {
        //     title: "Wheel Defect Gauge",
        //     link: "/services",
        //   },
        //   {
        //     title: "Wheel Diameter Gauge",
        //     link: "/services",
        //   },
        //   {
        //     title: "Wheel Distance Gauge",
        //     link: "/services",
        //   },
        //   {
        //     title: "A-Dimension Gauge",
        //     link: "/services",
        //   },
        //   {
        //     title: "Operating Handle Guage",
        //     link: "/services",
        //   },
        //   {
        //     title: "Laminar air flow chamber",
        //     link: "/services",
        //   },
        //   {
        //     title: "Bacteriological incubator",
        //     link: "/services",
        //   },
        //   {
        //     title: "Aligning wing limit gauge",
        //     link: "/services",
        //   },
        //   {
        //     title: "Air pressure modulator",
        //     link: "/services",
        //   },
        //   {
        //     title: "OH Gauge",
        //     link: "/services",
        //   },
        //   {
        //     title: "knuckle locking face gauge",
        //     link: "/services",
        //   },
        //   {
        //     title: "Knuckle Stretch cum nose wear limit gauge",
        //     link: "/services",
        //   },
        //   {
        //     title: "High precision gauge cum level",
        //     link: "/services",
        //   },
        //   {
        //     title: "Bent link gauge",
        //     link: "/services",
        //   },
        //   {
        //     title: "Brake block hanger gauge",
        //     link: "/services",
        //   },
        //   {
        //     title: "Rail Gauge",
        //     link: "/services",
        //   },
        // ],
      },
      {
        title: "THERMAL",
        link: "/gallery",
        // dropdown: [
        //   {
        //     title: "Hot Air Oven",
        //     link: "/services",
        //   },
        //   {
        //     title: "Muffle Furnace",
        //     link: "/services",
        //   },
        //   {
        //     title: "Digital Thermometer",
        //     link: "/services",
        //   },
        //   {
        //     title: "Dial Temperature Gauge",
        //     link: "/services",
        //   },
        //   {
        //     title: "BOD Incubator",
        //     link: "/services",
        //   },
        //   {
        //     title: "Agine Oven",
        //     link: "/services",
        //   },
        //   {
        //     title: "Pen Type Thermometer",
        //     link: "/services",
        //   },
        //   {
        //     title: "Digital Temperature & Humidity Meter",
        //     link: "/services",
        //   },
        //   {
        //     title: "Digital Thermo hygrometer",
        //     link: "/services",
        //   },
        //   {
        //     title: "Digital Temperature Controller with Sensor",
        //     link: "/services",
        //   },
        //   {
        //     title: "Digital temperature Indicator with Sensor",
        //     link: "/services",
        //   },
        //   {
        //     title: "Water Bath",
        //     link: "/services",
        //   },
        //   {
        //     title: "Dry Block Furnace",
        //     link: "/services",
        //   },
        //   {
        //     title: "Furnace",
        //     link: "/services",
        //   },
        //   {
        //     title: "Oil Bath",
        //     link: "/services",
        //   },
        //   {
        //     title: "Glass Thermometer",
        //     link: "/services",
        //   },
        //   {
        //     title: "Hot Plate",
        //     link: "/services",
        //   },
        //   {
        //     title: "Infrared Thermometer",
        //     link: "/services",
        //   },
        //   {
        //     title: "Temperature Gun",
        //     link: "/services",
        //   },
        //   {
        //     title: "Data Logger with Sensor",
        //     link: "/services",
        //   },
        //   {
        //     title: "Laser Gun",
        //     link: "/services",
        //   },
        //   {
        //     title: "Thermocouple",
        //     link: "/services",
        //   },
        //   {
        //     title: "RTD Sensor",
        //     link: "/services",
        //   },
        //   {
        //     title: "Thermal Imager",
        //     link: "/services",
        //   },
        //   {
        //     title: "Thermal Mapping of Industrial Furnace",
        //     link: "/services",
        //   },
        // ],
      },
      {
        title: "ELECTRO - TECHNICAL",
        link: "/gallery",
        // dropdown: [
        //     {
        //       title: "Digital Clamp Meter",
        //       link: "/services",
        //     },
        //     {
        //       title: "Digital Multimeter 6.5 digits",
        //       link: "/services",
        //     },
        //     {
        //       title: "Digital Multimeter 5.5 digits",
        //       link: "/services",
        //     },
        //     {
        //       title: "Digital Multimeter 4.5 digits",
        //       link: "/services",
        //     },
        //     {
        //       title: "Digital Multimeter 3.5 digits",
        //       link: "/services",
        //     },
        //     {
        //       title: "Voltmeter",
        //       link: "/services",
        //     },
        //     {
        //       title: "Amp meter",
        //       link: "/services",
        //     },
        //     {
        //       title: "Frequency Meter",
        //       link: "/services",
        //     },
        //     {
        //       title: "Capacitance Meter",
        //       link: "/services",
        //     },
        //     {
        //       title: "LCR Meter",
        //       link: "/services",
        //     },
        //     {
        //       title: "Insulation Tester",
        //       link: "/services",
        //     },
        //     {
        //       title: "Earth Tester",
        //       link: "/services",
        //     },
        //     {
        //       title: "Earth Leakage Tester",
        //       link: "/services",
        //     },
        //     {
        //       title: "KV Meter",
        //       link: "/services",
        //     },
        //     {
        //       title: "Power Factor Meter",
        //       link: "/services",
        //     },
        //     {
        //       title: "Energy Meter (Single Phase)",
        //       link: "/services",
        //     },
        //     {
        //       title: "High Voltage Tester",
        //       link: "/services",
        //     },
        //     {
        //       title: "Digital Temperature Controller",
        //       link: "/services",
        //     },
        //     {
        //       title: "Digital Temperature Indicator",
        //       link: "/services",
        //     },
        //     {
        //       title: "PID Controller",
        //       link: "/services",
        //     },
        //     {
        //       title: "Stop watch",
        //       link: "/services",
        //     },
        //     {
        //       title: "Timer",
        //       link: "/services",
        //     },
        //     {
        //       title: "Ohm Meter",
        //       link: "/services",
        //     },
        //     {
        //       title: "Wattmeter",
        //       link: "/services",
        //     },
        //     {
        //       title: "Signal Generator",
        //       link: "/services",
        //     },
        //     {
        //       title: "Oscilloscope (2 Channel)",
        //       link: "/services",
        //     },
        //     {
        //       title: "Relay",
        //       link: "/services",
        //     },
        //     {
        //       title: "Power Meter",
        //       link: "/services",
        //     },
        //     {
        //       title: "Power Analyzer",
        //       link: "/services",
        //     },
        //     {
        //       title: "H.V Probe",
        //       link: "/services",
        //     },
        //     {
        //       title: "Shunt",
        //       link: "/services",
        //     },
        //     {
        //       title: "Current Transformer",
        //       link: "/services",
        //     },
        //     {
        //       title: "Current Coil",
        //       link: "/services",
        //     },
        //     {
        //       title: "Multifunction Calibrator",
        //       link: "/services",
        //     },
        //     {
        //       title: "Process Calibrator",
        //       link: "/services",
        //     },
        //     {
        //       title: "Temperature Calibrator",
        //       link: "/services",
        //     },
        //     {
        //       title: "Decade Resistance Box",
        //       link: "/services",
        //     },
        //     {
        //       title: "Decade Mega ohm Box",
        //       link: "/services",
        //     },
        //     {
        //       title: "Decade Capacitance Box",
        //       link: "/services",
        //     },
        //     {
        //       title: "Decade Inductance Box",
        //       link: "/services",
        //     },
        //     {
        //       title: "Micro Ohm Meter",
        //       link: "/services",
        //     },
        //     {
        //       title: "Transformer Ratio Meter",
        //       link: "/services",
        //     },
        //     {
        //       title: "3 Phase Energy Meter",
        //       link: "/services",
        //     },
        //     {
        //       title: "Variable AC power Supply",
        //       link: "/services",
        //     },
        //     {
        //       title: "Variable DC Power supply",
        //       link: "/services",
        //     },
        //     {
        //       title: "Regulated DC Power Supply",
        //       link: "/services",
        //     },
        //     {
        //       title: "AC Leaskge Tester",
        //       link: "/services",
        //     },
        //     {
        //       title: "3 Phase Rotation Meter",
        //       link: "/services",
        //     },
        //     {
        //       title: "ARC Welding Machine",
        //       link: "/services",
        //     },
        //     {
        //       title: "Mig Welding Machine",
        //       link: "/services",
        //     },
        //     {
        //       title: "mV meter",
        //       link: "/services",
        //     },
        //     {
        //       title: "Digital Calibrator",
        //       link: "/services",
        //     },
        //     {
        //       title: "mV/ mA source",
        //       link: "/services",
        //     },
        //     {
        //       title: "Loop Calibrator",
        //       link: "/services",
        //     },
        //     {
        //       title: "Hour Meter",
        //       link: "/services",
        //     },
        //     {
        //       title: "Pyrometer",
        //       link: "/services",
        //     },

        // ],
      },
      {
        title: "MECHANICAL",
        link: "/gallery",
        // dropdown: [
        //   {
        //     title: "External Micrometer/Flange/Blade/Knife Edge",
        //     link: "/services",
        //   },
        //   {
        //     title: "External Micrometer",
        //     link: "/services",
        //   },
        //   {
        //     title: "Vernier Caliper",
        //     link: "/services",
        //   },
        //   {
        //     title: "Point type Micrometer",
        //     link: "/services",
        //   },
        //   {
        //     title: "Dial Gauge",
        //     link: "/services",
        //   },
        //   {
        //     title: "Steel Scale",
        //     link: "/services",
        //   },
        //   {
        //     title: "Feeler Gauge",
        //     link: "/services",
        //   },
        //   {
        //     title: "Radius Gauge",
        //     link: "/services",
        //   },
        //   {
        //     title: "Magnetic V-Block",
        //     link: "/services",
        //   },
        //   {
        //     title: "Thread Pitch Gauge",
        //     link: "/services",
        //   },
        //   {
        //     title: "Plain Plug Gauge",
        //     link: "/services",
        //   },
        //   {
        //     title: "Micrometer Stand",
        //     link: "/services",
        //   },
        //   {
        //     title: "Surface Plate",
        //     link: "/services",
        //   },
        //   {
        //     title: "Adjustable Snap Gauge",
        //     link: "/services",
        //   },
        //   {
        //     title: "Fixed Snap Gauge",
        //     link: "/services",
        //   },
        //   {
        //     title: "Test Sieve",
        //     link: "/services",
        //   },
        //   {
        //     title: "Cube Testing Machine",
        //     link: "/services",
        //   },
        //   {
        //     title: "Compression Testing Machine",
        //     link: "/services",
        //   },
        //   {
        //     title: "UTM",
        //     link: "/services",
        //   },
        //   {
        //     title: "TTM",
        //     link: "/services",
        //   },
        //   {
        //     title: "Proving Ring",
        //     link: "/services",
        //   },
        //   {
        //     title: "Torque Wrench",
        //     link: "/services",
        //   },
        //   {
        //     title: "Height Gauge",
        //     link: "/services",
        //   },
        //   {
        //     title: "Depth Micrometer",
        //     link: "/services",
        //   },
        //   {
        //     title: "Vernier Depth Gauge",
        //     link: "/services",
        //   },
        //   {
        //     title: "Depth Gauge",
        //     link: "/services",
        //   },
        //   {
        //     title: "Inside Micrometer",
        //     link: "/services",
        //   },
        //   {
        //     title: "Micrometer Head",
        //     link: "/services",
        //   },
        //   {
        //     title: "Bore Gauge",
        //     link: "/services",
        //   },
        //   {
        //     title: "Try Square / Engineering Square",
        //     link: "/services",
        //   },
        //   {
        //     title: "Angle Plate",
        //     link: "/services",
        //   },
        //   {
        //     title: "Angle Protractor",
        //     link: "/services",
        //   },
        //   {
        //     title: "Bevel Protractor",
        //     link: "/services",
        //   },
        //   {
        //     title: "Combination Set",
        //     link: "/services",
        //   },
        //   {
        //     title: "Coating Thickness Gauge",
        //     link: "/services",
        //   },
        //   {
        //     title: "Test Foil",
        //     link: "/services",
        //   },
        //   {
        //     title: "Sine Bar",
        //     link: "/services",
        //   },
        //   {
        //     title: "Length Bar",
        //     link: "/services",
        //   },
        //   {
        //     title: "Spirit Level",
        //     link: "/services",
        //   },
        //   {
        //     title: "Electronic Level",
        //     link: "/services",
        //   },
        //   {
        //     title: "Rockwell Hardness Tester",
        //     link: "/services",
        //   },
        //   {
        //     title: "Vickers Hardness Tester",
        //     link: "/services",
        //   },
        //   {
        //     title: "Brinell Hardness tester",
        //     link: "/services",
        //   },
        //   {
        //     title: "Square Gauge Block",
        //     link: "/services",
        //   },
        //   {
        //     title: "Pistol Caliper",
        //     link: "/services",
        //   },
        //   {
        //     title: "Profile Projector",
        //     link: "/services",
        //   },
        //   {
        //     title: "DFT Meter",
        //     link: "/services",
        //   },
        //   {
        //     title: "Pressure Gauge",
        //     link: "/services",
        //   },
        //   {
        //     title: "Vacuum Gauge",
        //     link: "/services",
        //   },
        //   {
        //     title: "Pressure Transmitter",
        //     link: "/services",
        //   },
        //   {
        //     title: "Pressure Transducer",
        //     link: "/services",
        //   },
        //   {
        //     title: "Differential Pressure Transmitter",
        //     link: "/services",
        //   },
        //   {
        //     title: "Level Transmitter",
        //     link: "/services",
        //   },
        //   {
        //     title: "Digital Balance",
        //     link: "/services",
        //   },
        //   {
        //     title: "Measuring Cylinder",
        //     link: "/services",
        //   },
        //   {
        //     title: "Burette",
        //     link: "/services",
        //   },
        //   {
        //     title: "Pipette",
        //     link: "/services",
        //   },
        //   {
        //     title: "Measuring Flask",
        //     link: "/services",
        //   },
        //   {
        //     title: "Weight Box",
        //     link: "/services",
        //   },
        //   {
        //     title: "Density Hydrometer",
        //     link: "/services",
        //   },
        //   {
        //     title: "Loose weights",
        //     link: "/services",
        //   },
        //   {
        //     title: "Ultrasonic Thickness Gauge",
        //     link: "/services",
        //   },
        //   {
        //     title: "Digital Tachometer",
        //     link: "/services",
        //   },
        //   {
        //     title: "Push Pull Meter",
        //     link: "/services",
        //   },
        //   {
        //     title: "Shore A Hardness Tester",
        //     link: "/services",
        //   },
        //   {
        //     title: "Shore D Hardness Tester",
        //     link: "/services",
        //   },
        //   {
        //     title: "Hardness Blocks (HRC)",
        //     link: "/services",
        //   },
        //   {
        //     title: "Hardness Blocks (HRBW)",
        //     link: "/services",
        //   },
        //   {
        //     title: "Hardness Blocks (HV)",
        //     link: "/services",
        //   },
        //   {
        //     title: "Ultrasonic Blocks",
        //     link: "/services",
        //   },
        //   {
        //     title: "B4 Flow Cup",
        //     link: "/services",
        //   },
        //   {
        //     title: "B5 Flow Cup",
        //     link: "/services",
        //   },
        //   {
        //     title: "Pi-Tape",
        //     link: "/services",
        //   },
        //   {
        //     title: "Double Ball Micrometer",
        //     link: "/services",
        //   },
        //   {
        //     title: "Compound Gauge",
        //     link: "/services",
        //   },
        //   {
        //     title: "Microscope",
        //     link: "/services",
        //   },
        //   {
        //     title: "Centrifuge",
        //     link: "/services",
        //   },
        //   {
        //     title: "Micropipette",
        //     link: "/services",
        //   },
        //   {
        //     title: "Lever Type Dial Gauge",
        //     link: "/services",
        //   },
        //   {
        //     title: "Ultrasonic Thickness Gauge",
        //     link: "/services",
        //   },
        //   {
        //     title: "Combination Set",
        //     link: "/services",
        //   },
        //   {
        //     title: "Pen Type Coat Gauge",
        //     link: "/services",
        //   },
        //   {
        //     title: "Dial Thickness Gauge",
        //     link: "/services",
        //   },
        //   {
        //     title: "Slip Gauge Block",
        //     link: "/services",
        //   },
        //   {
        //     title: "Measuring Pin",
        //     link: "/services",
        //   },
        //   {
        //     title: "Sound Level Meter",
        //     link: "/services",
        //   },
        //   {
        //     title: "Tachometer",
        //     link: "/services",
        //   },
        //   {
        //     title: "Cube Mould",
        //     link: "/services",
        //   },
        //   {
        //     title: "RPM Meter",
        //     link: "/services",
        //   },
        //   {
        //     title: "Water Flow Meter",
        //     link: "/services",
        //   },
        //   {
        //     title: "Weld Fillet Gauge",
        //     link: "/services",
        //   },
        //   {
        //     title: "Shore-A Hardness Tester",
        //     link: "/services",
        //   },
        //   {
        //     title: "Infrared Thermometer",
        //     link: "/services",
        //   },
        //   {
        //     title: "Dig. Surface Roughness Tester",
        //     link: "/services",
        //   },
        //   {
        //     title: "Taper Gauge/ Taper Scale",
        //     link: "/services",
        //   },
        //   {
        //     title: "Bridge Cam Gauge",
        //     link: "/services",
        //   },
        //   {
        //     title: "High Low Welding Gauge",
        //     link: "/services",
        //   },
        //   {
        //     title: "Dig. Surface Profile Gauge",
        //     link: "/services",
        //   },
        //   {
        //     title: "Impact Test Weight",
        //     link: "/services",
        //   },
        //   {
        //     title: "Digital Viberation Meter",
        //     link: "/services",
        //   },
        //   {
        //     title: "PORTABLE HARDNESS TESTER",
        //     link: "/services",
        //   },
        //   {
        //     title: "Auto Pull Tester",
        //     link: "/services",
        //   },
        // ],
      },
    ],
  },
  // {
  //   title: "Projects",
  //   link: "/projects",
  // },
  {
    title: "Contact Us",
    link: "/contact",
  },
];

export default HeaderDataAlpha;
