import React, { memo } from "react";

// Libraries
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { PropTypes } from "prop-types";

// components
import SocialIcons from "../SocialIcon/SocialIcons";
import FooterMenu, { Footer } from "./Footer";

// Data
import FooterData from "./FooterData";

const iconData = [
  {
    color: "#828282",
    link: "https://www.facebook.com/rsscclab/",
    icon: "fab fa-facebook-f",
  },
  // {
  //     color: "#828282",
  //     link: "https://dribbble.com/",
  //     icon: "fab fa-dribbble"
  // },
  // {
  //   color: "#828282",
  //   link: "https://twitter.com/",
  //   icon: "fab fa-twitter",
  // },
  {
    color: "#828282",
    link: "https://www.linkedin.com/company/rssafetycalibration/",
    icon: "fab fa-linkedin",
  },
  {
    color: "#828282",
    link: "https://www.instagram.com/rsscc_lab/",
    icon: "fab fa-instagram",
  },
];

const FooterStyle02 = (props) => {
  return (
    <Footer
      theme={props.theme}
      className={`${props.className ? ` ${props.className}` : ""}`}
    >
      <div className="py-[5%] lg:py-[8%] md:pb-0 xs:py-[8%]">
        <Container>
          <Row className="md:text-start justify-between">
            <Col
              lg={{ span: 3, order: 0 }}
              md={5}
              sm={{ span: 6, order: 1 }}
              className="md:mb-[50px] xs:mb-[25px]"
            >
              <Link aria-label="link" to="/" className="mb-[20px] block">
                <img
                  alt="logo"
                  src={"/assets/img/rs-calibration/header-logo/logo.webp"}
                  width="111"
                  height="36"
                />
              </Link>
              {/* <p className="w-[80%] mb-[20px] xs:w-full">
                Founded in 2013, RS Safety & Calibration (PVT LTD) emerged
                with a mission to address the testing and calibration
                requirements of engineering industries
              </p> */}
              <SocialIcons
                theme="social-icon-style-01"
                className="justify-start pt-4"
                size="xs"
                iconColor={props.theme === "dark" ? "light" : "dark"}
                data={iconData}
              />
            </Col>
            <FooterMenu
              className="xl:px-[15px] md:mb-[40px] xs:mb-[25px]"
              data={FooterData.slice(0, 3)}
              lg={{ span: 2, order: 0 }}
              sm={{ span: 4, order: 3 }}
              titleClass="capitalize"
            />
            <Col
              lg={{ span: 3, order: 0 }}
              md={5}
              sm={{ span: 6, order: 2 }}
              className=" md:mb-[20px]"
            >
              <span className="font-serif font-medium block text-[#47B66D] mb-[20px]">
                GET IN TOUCH
              </span>
              <ul>
                <li className="flex flex-col">
                  83, Upper Basement, Aakash Deep Plaza Golmuri-831003,
                  Jamshedpur, Jharkhand, India.
                  {/* <div>
                      <Link aria-label="link" to="/blogs/blog-post-layout-01">
                        14 Tottenham Road, London, England
                      </Link>
                    </div> */}
                </li>
                <li className="flex gap-1 items-cente mt-2">
                  <i className="feather-mail text-lg mr-[10px] text-themecolor"></i>
                  <div className="flex flex-col gap-y-2 leading-[1.5]">
                    <a href="mailTo:rsscc.lab@gmail.com">rsscc.lab@gmail.com</a>
                    <a href="mailTo:rsscc.sales@gmail.com">
                       rsscc.sales@gmail.com
                    </a>
                  </div>
                </li>
                <li className="flex  mt-2 gap-1 items-cente">
                  <i className="feather-phone-call text-lg mr-[10px] text-themecolor"></i>
                  <div className="flex flex-wrap   gap-y-2  leading-[1.5]">
                    <a href="tel:+918271143143">+91 8271143143 </a> ,
                    <a href="tel:+918235044143" className="ml-[2px]">
                      +91 8235044143
                    </a>
                    ,<a href="tel:+919122333456">+91 9122333456</a>
                  </div>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="py-[15px] border-t border-[#8890a4] text-[#8f99b1] text-[16px]  font-normal leading-[30px]">
        <Container>
          <Row className="md:flex-col md:justify-center flex items-center">
            <Col md={6} className="md:mb-[0.75rem] xs:mb-[15px]">
              <small className="flex  justify-start lg:justify-center sm:justify-start">
                {/* <li className="mr-[35px] md:mr-[20px] xl:mb-0 xs:mb-[7px]"><Link aria-label="link" to="#">Privacy policy</Link></li>
                                <li className="mr-[35px] md:mr-[20px] xl:mb-0 xs:mb-[7px]"><Link aria-label="link" to="#">Legal notice</Link></li>
                                <li className="xl:mb-0"><Link aria-label="link" to="#">Terms of service</Link></li> */}
                <p className="text-center md:leading-[1.2] md:text-[12px]">
                  © 2024 RS Calibration | All Rights Reserved.
                </p>
              </small>
            </Col>
            {/* lg:justify-center sm:justify-start */}
            <Col md={6}>
              <small className="flex justify-end lg:justify-center sm:justify-start ">
                <p className="md:leading-[1.2] md:text-[12px] text-center">
                  Developed by :
                </p>
                <div className="flex gap-1 items-center">
                  <a
                    href="https://teckat.com/"
                    target="_blank"
                    className="flex gap-1 items-center  decoration-transparent md:leading-[1.2] md:text-[12px] hover:!text-[#47B66D]"
                    rel="noreferrer"
                  >
                    <img
                      src="/assets/img/rs-calibration/teckat-logo.png"
                      alt=""
                      className="w-[23px] h-[23px] md:w-[18px] md:h-[18px] ml-2"
                    />
                    Teckat.com
                  </a>
                </div>
              </small>
            </Col>
          </Row>
        </Container>
      </div>
    </Footer>
  );
};

FooterStyle02.defaultProps = {
  data: FooterData,
  logo: "/assets/img/webp/logo-white.webp",
};

FooterStyle02.propTypes = {
  className: PropTypes.string,
  logo: PropTypes.string,
};

export default memo(FooterStyle02);
