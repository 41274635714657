import React from "react";
import { Col, Container, Row } from "react-bootstrap";

//components
import Buttons from "../../Components/Button/Buttons";

const AboutCareer = () => {
  return (
    <>
      <section className="py-[130px] lg:py-[90px] md:py-[75px] sm:py-[50px]">
        <Container>
          <Row className="items-center justify-center">
            <Col
              lg={7}
              md={10}
              className="flex justify-end lg:pl-[40px] md:mb-[50px]"
            >
              <figure className="relative m-0">
                <img
                  height=""
                  width=""
                  src="/assets/img/rs-calibration/career/new-whyworkhere.webp"
                  alt=""
                />
                <figcaption className="absolute bg-[#ff6437] bottom-[-20px] left-[-50px] text-white p-[49px] lg:p-[45px] md:p-[42px] sm:p-[38px] w-[50%] xs:w-full xs:left-[-25px]">
                  <span className="uppercase text-sm tracking-[1px] text-darkgray font-serif font-medium mb-[10px] inline-block">
                    Join Our Team
                  </span>
                  <h6 className="font-serif uppercase font-medium mb-0 text-[20px]">
                    We invest in you
                  </h6>
                </figcaption>
              </figure>
            </Col>
            <Col xl={{ span: 4, offset: 1 }} lg={5} md={9}>
              <span className="font-serif text-[30px] text-darkgray leading-[40px] tracking-[-0.5px] font-medium inline-block mb-[25px] sm:text-[20px] sm:leading-[30px]">
                Why Work Here?
              </span>
              <p className="mb-[25px] md:mb-[20px] sm:mb-[15px]">
                At RS Safety & Calibration, we're more than just a company;
                we're a passionate team driven by a shared mission. We believe
                in fostering a collaborative and innovative environment where
                talented individuals can thrive and make a real impact.
              </p>
              <Buttons
                href="#"
                className="font-medium font-serif after:h-[2px] after:bg-black hover:text-black uppercase btn-link text-md md:mb-0"
                size="xl"
                color="#232323"
                title="Apply Now"
              />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default AboutCareer;
