import React, { useEffect, useState } from "react";
import Header, {
  //   HeaderLanguage,
  HeaderNav,
  Menu,
  //   SearchBar,
  Topbar,
} from "./Header";
import { Col, Container, Navbar, Row } from "react-bootstrap";
import SocialIcons from "../SocialIcon/SocialIcons";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Buttons from "../Button/Buttons";
import "./HeaderMain.scss";

const SocialIconsData = [
  {
    color: "#0038e3",
    link: "https://www.facebook.com/rsscclab/",
    icon: "fab fa-facebook-f",
  },
  // {
  //   color: "#0038e3",
  //   link: "https://dribbble.com/",
  //   icon: "fab fa-dribbble",
  // },
  // {
  //   color: "#0038e3",
  //   link: "https://twitter.com/",
  //   icon: "fab fa-twitter",
  // },
  {
    color: "#0038e3",
    link: "https://www.linkedin.com/company/rssafetycalibration/",
    icon: "fab fa-linkedin",
  },
  {
    color: "#0038e3",
    link: "https://www.instagram.com/rsscc_lab/",
    icon: "fab fa-instagram",
  },
];
const HeaderMain = (props) => {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const currentPath = location.pathname;
  useEffect(() => {
    console.log(currentPath);
  });

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      {/* Header Start */}
      <Header
        className={`header-with-topbar ${
          currentPath === "/" ? "home_header" : ""
        }`}
        topSpace={{ md: true }}
        type="reverse-scroll"
      >
        <Topbar className="bg-[#1e1e1ecc] px-[5px] md:px-[10px] sm:hidden text-[#fff] ">
          <Container fluid>
            <Row>
              <Col
                xs="auto"
                className="text-center items-center flex sm:text-start mr-auto pl-0 md:hidden"
              >
                <SocialIcons
                  theme="social-icon-style-01"
                  iconColor="light"
                  size="xs"
                  data={SocialIconsData.slice(0, 4)}
                />
              </Col>
              <Col xs="auto" className="py-[3px] text-sm">
                <span className="py-[9px] px-[18px] inline-block">
                  <a href="tel:+918271143143" className="hover:!text-white">
                    <i className="feather-phone-call text-white mr-[5px] text-md relative top-[3px]"></i>
                    +91 8271143143
                  </a>
                </span>
                <span className="py-[9px] pl-[18px] pr-[7px] md:pr-0 inline-block md:hidden">
                  <i className="feather-map-pin text-white mr-[6px] text-md relative top-[1px]"></i>
                  83, Upper Basement, Aakash Deep Plaza Golmuri-831003,
                  Jamshedpur, Jharkhand, India.
                </span>
              </Col>
            </Row>
          </Container>
        </Topbar>
        <HeaderNav
          fluid="fluid"
          theme="light"
          bg="white"
          menu="light"
          className="px-[35px] !my-[7px]  md:px-[15px] sm:px-0 !bg-transparent"
          containerClass="md:px-0 !min-h-fit"
        >
          <Col className="col-auto col-sm-6 col-lg-2 me-auto ps-lg-0">
            <Link aria-label="header logo" className="flex items-center" to="/">
              <Navbar.Brand className="inline-block p-0 m-0 w-fit">
                <img
                  className="default-logo !max-h-full md:!max-h-[25px] sm:!max-h-[20px]"
                  loading="lazy"
                  src="/assets/img/rs-calibration/header-logo/logo.webp"
                  data-rjs="/assets/img/rs-calibration/header-logo/logo.webp"
                  alt="logo"
                />
                <img
                  className="alt-logo !max-h-[50px] md:!max-h-[25px] sm:!max-h-[20px]"
                  loading="lazy"
                  src="/assets/img/rs-calibration/header-logo/logo-2.webp"
                  data-rjs="/assets/img/rs-calibration/header-logo/logo-2.webp"
                  alt="logo"
                />
                <img
                  className="mobile-logo !max-h-[50px] md:!max-h-[25px] sm:!max-h-[20px]"
                  loading="lazy"
                  src="/assets/img/rs-calibration/header-logo/logo.webp"
                  data-rjs="/assets/img/rs-calibration/header-logo/logo-2.webp"
                  alt="logo"
                />
              </Navbar.Brand>
            </Link>
          </Col>
          <Navbar.Toggle
            className={`order-last md:mx-[15px] shadow-none ${
              !open ? "collapsed" : ""
            }`}
            onClick={handleToggle}
          >
            <span className="navbar-toggler-line !bg-[#47B66D]"></span>
            <span className="navbar-toggler-line !bg-[#47B66D]"></span>
            <span className="navbar-toggler-line !bg-[#47B66D]"></span>
            <span className="navbar-toggler-line !bg-[#47B66D]"></span>
          </Navbar.Toggle>
          <Navbar.Collapse className="col-auto justify-end p-0" in={open}>
            <Menu {...props} handleClose={handleClose} />
          </Navbar.Collapse>
          <Col className="col-auto text-right !pr-0 pl-[15px] md:pl-0 md:pr-[15px] sm:pr-0">
            <Col className=" pe-0 pl-[0px]">
              <Buttons
                ariaLabel="Contact"
                href="https://wa.me/9122333456"
                className="font-medium font-serif uppercase rounded-[4px]  contact-btn "
                themeColor="#2ED47C"
                color="#2ED47C"
                size="md"
                title="Whatsapp Us"
                icon="fab fa-whatsapp fa-lg"
                iconPosition="after"
                target="_blank"
              />
            </Col>
          </Col>
        </HeaderNav>
      </Header>
      {/* Header End */}
    </>
  );
};

export default HeaderMain;
