import React from "react";
import FooterStyle from "./FooterStyle";


const FooterMain = () => {
  
  return <FooterStyle theme="dark" className="bg-[#122652] text-slateblue" />;
};


export default FooterMain;
