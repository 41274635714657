import React from "react";
import Banner from "../../Components/Banner/Banner";
import AboutCareer from "./AboutCareer";
import CareerContact from "./CareerContact";

const Career = () => {
  return (
    <>
      <div className="mt[150px]">
        <Banner
          image="/assets/img/rs-calibration/about/breadcrumb/breadcrumb.webp"
          title="Career"
        />

        {/* About  cazreer start */}
        <AboutCareer></AboutCareer>
        {/* About  cazreer Ends */}

        {/* Career Contact start */}
        <CareerContact></CareerContact>
        {/* Career Contact ends */}
      </div>
    </>
  );
};

export default Career;
